@font-face {
    font-family: 'Comic Sans';
    src: url('./fonts/ComicSans.ttf') format('truetype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Stjernekrig';
    src: url('./fonts/stjernekrig.ttf') format('truetype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Skog';
    src: url('./fonts/wild.ttf') format('truetype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Barnebok';
    src: url('./fonts/winnie.ttf') format('truetype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Tegnefilm';
    src: url('./fonts/bolde.otf') format('opentype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Tøff';
    src: url('./fonts/dad.otf') format('opentype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Handskrevet';
    src: url('./fonts/Handskrevet.otf') format('opentype');
    /* Add additional font properties if needed */
}

@font-face {
    font-family: 'Magisk';
    src: url('./fonts/wizard.otf') format('opentype');
    /* Add additional font properties if needed */
}
