.App {
  text-align: center;
}

header {
  background-color: whitesmoke;
  padding: 12px;
}

.header-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.banner-content {
  background-color: palegreen;
  padding: 1px;
}

.banner-content h2 {
  font-family: 'Courier New';
  font-size: 50px;
}

.banner-content p {
  font-family: 'Courier New';
  font-size: 50px;
}

.logo img {
  height: 240px;
  margin: 12px;
}

.media {
  display: flex;
  align-items:flex-end;
  justify-content: center;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
}

.media img {
  width: 36px;
  height: 36px;
  margin: 12px;
}

header h1 {
  margin: 12px;
}

main {
  padding: 12px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;
  border: 1px solid grey;
  border-radius: 12px;
  padding: 12px;
  width: 30%;
  background-color: whitesmoke;
}

.carousel-container {
  width: 55%;
  margin: 12px;
}

.card-content {
  padding: 12px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content > div:nth-child() {
  flex-grow: 1;
}

.card-lone-content {
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-lone-content > div:nth-child() {
  flex-grow: 1;
}

.card-button {
  width: 150px;
  margin: 6px auto;
  padding: 12px;
  border-radius: 12px;
  background-color: #48CFC8;
  color: black;
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.card-button:hover {
  background-color: #3BB8AC;
  color: whitesmoke;
}

footer {
  background-color: whitesmoke;
  padding: 12px;
}

@media (max-width: 2300px) {
  .card {
    width: 45%;
  }
}

@media (max-width: 1400px) {
  .card {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .header-content {
    justify-content: center;
  }

  .banner-content h2 {
    font-size: 25px;
  }

  .banner-content p {
    font-size: 25px;
  }

  .card-content {
    width: 90%;
  }
  
  .carousel-container {
    width: 90%;
  }
}